<template>
  <b-modal id="set-player-turn-modal" :title="`ใส่เทิร์นผู้เล่น${isLoading ? '' : ` - ${player.username}`}`" hide-footer>
    <b-overlay :show="isSubmitting">
      <b-form @submit.prevent="onSubmit">
        <div v-if="isLoading" class="text-center py-4">
          <b-spinner />
        </div>
        <div v-else>
          <h5>
            โปรโมชั่นล่าสุด
          </h5>
          <div v-if="!latestPromotion">
            <i>ยังไม่มีโปรโมชั่นล่าสุด</i>
          </div>
          <div v-else>
            <b-form-group
              label="ชื่อโปรโมชั่น"
              label-cols="12"
              label-cols-md="3"
            >
              <b-input :value="latestPromotion.promotion.title" readonly />
            </b-form-group>

            <b-form-group
              label="ประเภทเทิร์น"
              label-cols="12"
              label-cols-md="3"
            >
              <b-input :value="getTurnTypeLabel(latestPromotion.promotion.turnType)" readonly />
            </b-form-group>

            <b-form-group
              v-if="+latestPromotion.promotion.turnType !== 2"
              label="จำนวนเทิร์น"
              label-cols="12"
              label-cols-md="3"
            >
              <b-input-group append="เท่า">
                <b-input :value="latestPromotion.promotion.turnRate" readonly />
              </b-input-group>
            </b-form-group>

            <b-form-group
              label="อั้นถอน"
              label-cols="12"
              label-cols-md="3"
            >
              <b-input v-if="!latestPromotion.promotion.maxWithdrawType" readonly value="ไม่อั้นถอน" />
              <b-input-group v-else :append="+latestPromotion.promotion.maxWithdrawType === 0 ? 'เครดิต' : 'เท่า'">
                <b-input :value="latestPromotion.promotion.maxWithdraw" readonly />
              </b-input-group>
            </b-form-group>

            <b-form-group
              label="เกมที่เล่นได้"
              label-cols="12"
              label-cols-md="3"
            >
              <b-input :value="getAllowedGameLabel(latestPromotion.promotion.forGameType)" readonly />
            </b-form-group>

            <hr>

            <b-form-group
              label="ยอดฝากล่าสุด"
              label-cols="12"
              label-cols-md="3"
            >
              <b-input-group append="บาท">
                <b-input :value="latestPromotion.depositTransaction.amount" readonly />
              </b-input-group>
            </b-form-group>

            <b-form-group
              label="เวลาฝาก"
              label-cols="12"
              label-cols-md="3"
            >
              <b-input :value="depositDateTime" readonly />
            </b-form-group>

            <b-form-group
              label="ได้รับโบนัส"
              label-cols="12"
              label-cols-md="3"
            >
              <b-input-group append="เครดิต">
                <b-input :value="latestPromotion.bonusAmount" readonly />
              </b-input-group>
            </b-form-group>

            <b-form-group
              label="เทิร์นที่ต้องทำ"
              label-cols="12"
              label-cols-md="3"
            >
              <b-input-group append="เครดิต">
                <b-input :value="latestPromotion.turnTarget" readonly />
              </b-input-group>
            </b-form-group>

            <b-form-group
              label="เวลาที่รับโปร"
              label-cols="12"
              label-cols-md="3"
            >
              <b-input :value="claimPromotionDateTime" readonly />
            </b-form-group>


            <b-form-group
              label="ถอนเงิน"
              label-cols="12"
              label-cols-md="3"
            >
              <b-input
                :state="!!latestPromotion.withdrawTransactionId"
                :value="latestPromotion.withdrawTransactionId ? 'ได้ถอน' : 'ไม่ได้ถอน'"
                readonly
              />
            </b-form-group>
          </div>
        </div>
        <div class="text-right">
          <b-button class="mr-2" variant="light" @click="closeModal">
            {{ $t('buttons.cancel') }}
          </b-button>
          <b-button :disabled="isLoading" type="submit" variant="primary">
            ยืนยันการใส่เทิร์น
          </b-button>
        </div>
      </b-form>
    </b-overlay>
  </b-modal>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import dayjs from 'dayjs';

export default {
  name: 'SetPlayerTurnModal',
  props: {
    player: {
      type: Object,
      required: true,
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.player.isFetchingLatestPromotion,
      latestPromotion: (state) => state.player.latestPromotion,
      isSubmitting: (state) => state.player.isFetchingRestorePlayerTurn
    }),
    depositDateTime() {
      return dayjs(this.latestPromotion.depositTransaction.createdAt).format('HH:mm:ss DD/MM/YYYY')
    },
    claimPromotionDateTime() {
      return dayjs(this.latestPromotion.createdAt).format('HH:mm:ss DD/MM/YYYY')
    }
  },
  methods: {
    ...mapActions(['fetchLatestPromotion', 'restorePlayerTurn']),
    closeModal() {
      this.$bvModal.hide('set-player-turn-modal')
    },
    async onSubmit() {
      await this.restorePlayerTurn(this.player.id)
      this.closeModal()
      this.$emit('fetch-player')
    },
    getTurnTypeLabel(turnType) {
      switch (+turnType) {
        case 0:
          return 'เทิร์นเครดิต'
        case 1:
          return 'เทิร์นโอเวอร์'
        default:
          return 'ไม่มีเทิร์น'
      }
    },
    getAllowedGameLabel(forGameType) {
      switch (+forGameType) {
        case 0:
          return 'ทุกเกม'
        case 1:
          return 'เกมสล็อต'
        case 2:
          return 'เกมยิงปลา'
        case 3:
          return 'เกมคาสิโน'
        case 4:
          return 'เกมกีฬา'
        default:
          return 'เกมกราฟ'
      }
    }
  },
}
</script>
